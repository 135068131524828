.footer {
  &__inner {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 78px 0 82px 0;
    background-color: $colors-grey;
    color: $colors-white;
  }

  &__col {
    display: block;
  }

  .copyright {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
  }

  &__data {
    margin-bottom: 17px;

    a {
      font-weight: $font-weight-bold;
    }
  }

  &__social {
    ul {
      list-style-type: none;
      display: flex;
      gap: 20px;
      padding-left: 0;
    }

    li {
      width: 25px;
      height: 25px;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  a {
    text-decoration: none;
    font-weight: $font-weight-light;
    font-size: 18px;
    line-height: 21px;
    color: $colors-white;
  }
}
