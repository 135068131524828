.about {
  @include margins;

  &__text {
    font-size: 24px;
    max-width: 870px;
    line-height: 1.2;

    @media (max-width: 1600px) {
      font-size: 18px;
      max-width: 600px;
    }
  }

  //
  //@media (max-width: $screen-xl) {
  //  &__promo{
  //    top: 90px;
  //    right: 0;
  //  }
  //}
  //
  //@media (max-width: $screen-md) {
  //  &__promo{
  //    top: 140px;
  //    right: 0;
  //  }
  //}
}
