$font-family: Roboto, Arial, Helverica, sans-serif;
$font-size-title: 48px;
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 18px;
$font-size-lg: 21px;
$font-size-xl: 24px;
$font-size-xxl: 36px;
$font-size-xxxl: 64px;

$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1280px;

$colors-white: #ffffff;
$colors-grey: #40444e;
$colors-grey-light: #d9d9d9;
$colors-grey-lighter: #f9f9f9;
$colors-black: #000000;
$colors-orange: #f28e00;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$margin-top: 100px;
$margin-bottom: 100px;
$media-margin-top: 50px;
$media-margin-bottom: 50px;

$delay: .25s;
$transform-speed: $delay;