@import 'variables';
@import 'mixins';
@import 'normalize';
@import 'blocks/header';
@import 'blocks/footer';

@import 'pages/page';
@import 'pages/main_page';
@import 'pages/404';

body {
  font-family: 'Roboto', serif;
  font-weight: $font-weight-light;
  font-size: 18px;
  color: $colors-grey;
}

img {
  width: 100%;
}

@media (min-width: $screen-xl) {
  .container{
    width: 100%;
    max-width: 1524px;
  }
}

.h2, h2 {
  font-size: $font-size-title;
  font-weight: $font-weight-bold;
  margin-bottom: 80px;

  @media (max-width: $screen-md) {
    font-size: $font-size-title / 2;
    margin-bottom: 40px;
  }
}

main {
  min-height: 50vh;
}

.page__middle {
  position: relative;
}

.text-orange {
  color: $colors-orange;
  font-weight: $font-weight-bold;
  font-size: $font-size-xl;
  margin-bottom: 30px;
}

.list-arrow {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;

  li {
    list-style: none;
    display: inline;
    font-weight: $font-weight-medium;
    position: relative;
    padding-left: 35px;
    padding-bottom: 15px;

    &:before {
      content: "";
      height: 16px;
      width: 25px;
      position: absolute;
      top: 10px;
      left: 0;
      background-image: url('/local/templates/main/components/bbc/elements.list/manufactors/arrow.png');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  li:last-of-type {
    padding-bottom: 0;
  }
}

.grey-bubble-title {
  display: block;
  border-radius: 42px;
  padding: 10px 25px;
  margin: 25px 0;
  font-weight: $font-weight-bold;
  color: $colors-white;
  background-color: $colors-grey;
}

.arrow {
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  transition: $delay;

  &:after {
    @include pseudo();
    background-color: $colors-orange;
    mask-image: url("/local/templates/main/include/arrow-right.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    width: 100%;
    height: 100%;
    transition: $delay;
  }

  &__left {
    transform: rotate(180deg);
  }
}

.dots {
  display: flex;
  margin: 0 15px;
  .dot {
    background-color: #EEEEEE;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin: 2px;
    cursor: pointer;
    transition: $delay;

    &.active, &:hover {
      transition: $delay;
      background-color: $colors-orange;
    }
  }
}

.tablebodytext {
  display: none;
}

.splide {
  .splide__dot {
    background-color: #EEEEEE;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin: 2px;
    cursor: pointer;
    transition: $delay;
    border: 0;
    line-height: 0;
    padding: 0;

    &.is-active, &:hover {
      transition: $delay;
      background-color: $colors-orange;
    }
  }

  .splide__pagination {
    padding-left: 0;
    margin: 0 15px;
    flex-wrap: nowrap;

    li {
      line-height: 0;
    }
  }

  .splide__arrow {
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    transition: $delay;
    background-color: unset;
    border: 0;
    padding: 0;
    line-height: 0;

    &:hover {
      background-color: #EEEEEE;
      transition: $delay;
    }

    &:after {
      @include pseudo();
      background-color: $colors-orange;
      mask-image: url("/local/templates/main/include/arrow-right.svg");
      mask-repeat: no-repeat;
      mask-position: center;
      width: 100%;
      height: 100%;
      transition: $delay;
      top: 0;
    }

    &--prev {
      transform: rotate(180deg);
    }
  }

  &__arrows {
    margin-top: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}