.error-404 {

  &__wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 820px;
  }

  &__title {
    font-weight: $font-weight-bold;
    color: $colors-orange;
    font-size: 180px;
    line-height: 130px;
    margin-top: 70px;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-weight: $font-weight-bold;
    font-size: 48px;
  }

  &__description {
    font-size: 28px;
  }

  &__links {
    display: flex;
    flex-direction: row;
  }

  &__button {
    margin-top: 20px;
    background-color: $colors-orange;
    font-size: 18px;
    color: $colors-white;
    transition: $delay;
    padding: 10px 20px;
    border: 0;

    &:hover {
      background-color: lighten($colors-orange, 10);
      transition: $delay;
    }

    a {
      @include clear-links();
    }
  }
}