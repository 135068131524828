.header {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__left {
    margin-top: 12px;
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 85px;

    @media (max-width: 1370px) {
      margin-left: 20px;
    }
  }

  &__menu {
    width: calc(100% - 183px - 80px);
    &-top {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-bottom: 6px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        height: 1px;
        width: calc(100% - 240px);
        background-color: $colors-grey-light;
        z-index: 50;
      }
    }

    &-bottom {
      display: flex;
      justify-content: flex-end;
      margin: 8px 0 12px;

      &__item {
        margin-right: 70px;
      }

      &__link {
        font-weight: $font-weight-bold;
        color: $colors-black;
        text-decoration: none;
        font-size: $font-size-md;
        line-height: 21px;
        margin-right: 12.5px;

        &:hover {
          color: $colors-grey;
        }
      }
    }
  }

  &__phone {
    display: flex;
    justify-content: space-between;

    .callback {
      background: $colors-orange;
      font-weight: 400;
      font-size: $font-size-md;
      line-height: 21px;
      color: $colors-white;
      border: 0;
      padding: 10px 20px;
    }

    .numbers {
      padding-top: 5px;
    }

    a {
      color: $colors-black;
      text-decoration: none;
      font-weight: 300;
      font-size: $font-size-md;
      line-height: 21px;
    }
  }

  &__item:last-child {
    margin-left: 33px
  }

  .search {

    form {
      position: relative;
      display: flex;
    }

    &__button {
      position: absolute;
      z-index: 9;
      border: 0;
      background: transparent;
      top: 0;
      bottom: 0;
      left: 10px;
      font-weight: 300;
      font-size: $font-size-md;
      height: 40px;
      padding: 0;
    }

    #search__input {
      background: $colors-grey-light;
      height: 42px;
      font-size: $font-size-md;
      border: 0;
      padding: 15px 15px 15px 70px;
      width: 240px;

      &::placeholder {
        text-align: right;
      }
    }
  }

  .auth {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &__inner {
      display: flex;
      max-width: 290px;
    }

    &__item {
      display: flex;
      height: 114px;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-left: 33px
      }

      a {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        color: $colors-grey;

        &:hover, svg {
          color: $colors-grey-light;
          transition: $transform-speed;
        }

        svg {
          margin-right: 12px;
        }
      }
    }
  }

  .menu-mobile {
    display: none;
  }
}

@media (max-width: $screen-xl) {
  .header {
    padding-top: 20px;

    .auth {
      width: calc(100% - 80px);
      order: 1;

      &__inner {
        align-items: center;
      }

      &__item {
        height: 60px;

        a {
          font-size: 10px;
        }
      }
    }

    .logo {
      justify-content: center;
      order: 0;

      &__link {
        display: inline-block;
      }

      &__image {
        max-width: 70px;
      }
    }

    &__menu {
      order: 3;
      display: flex;
      margin-top: 20px;
      width: 100%;

      &-top {
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }

      &-bottom {
        margin: 0;
        width: 100%;
        justify-content: start;
      }
    }

    .menu-top {
      display: none;
    }

    &__phone {
      .numbers {
        display: none;
      }
      .callback {
        font-size: 10px;
        padding: 4px 10px;
        margin-left: 10px;
      }
    }

    .search {
      &__button {
        height: 30px;
        left: 5px;
      }
      #search__input {
        font-size: 10px;
        padding: 5px 5px 5px 30px;
        height: 30px;
        width: 120px;
      }
    }

    .menu-mobile {
      display: flex;
      position: relative;
      justify-content: end;
      align-items: center;

      &__button {
        cursor: pointer;
        .line {
          position: relative;
          width: 20px;
          height: 2px;
          background-color: $colors-grey;

          &--top, &--middle  {
            margin-bottom: 2px;
          }
        }
      }

    }
  }
}