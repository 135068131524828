@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin for-phone-only {
  @media (max-width: 576px) { @content; }
}
@mixin for-tablet-portrait {
  @media (max-width: $screen-sm) { @content; }
}
@mixin for-tablet-landscape {
  @media (max-width: $screen-md) { @content; }
}
@mixin for-desktop {
  @media (max-width: $screen-lg) { @content; }
}
@mixin for-big-desktop {
  @media (max-width: $screen-xl) { @content; }
}

@mixin margins {
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;

  @media (max-width: $screen-xl) {
    margin-top: $media-margin-top;
    margin-bottom: $media-margin-bottom;
  }
}

@mixin pseudo($pos: absolute, $display: block, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin clear-links() {
  text-decoration: none;
  color: inherit;
}